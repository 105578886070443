import React from 'react';
import ReactDOM from 'react-dom';
import { Beforeunload } from 'react-beforeunload';
//import { serialize, deserialize } from "react-serialize"
import './index.css';
//import characters from './characters.json';

class XPChangeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        if (! this.state.value.match(/^-?\d+$/)) {
            alert("Please enter an integer: " + this.state.value);
            event.preventDefault();
            return;
        }
        this.props.onSubmit(this.state.value);
        //alert("Marks changed by " + this.state.value);
        this.setState({value: ""});
        event.preventDefault();
    }

    render() {
        return (
        <form onSubmit={this.handleSubmit}>
            <label>
            Change:
            <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <input type="submit" value="Submit" />
        </form>
        );
    }
}

class AddRowForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            level: '1',
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleChangeLevel(event) {
        this.setState({level: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        if (! this.state.level.match(/^-?\d+$/)) {
            alert("Please enter an integer for the level: " + this.state.level);
            event.preventDefault();
            return;
        }
        if (this.state.name.match(/^\s*$/)) {
            alert("Please enter a name");
            event.preventDefault();
            return;
        }
        this.props.onSubmit(this.state.name, this.state.level);
        //alert("Added a character with name " + this.state.name + " and starting at level " + this.state.level);
        this.setState({
            name: "",
            level: "1",
        });
    }

    render() {
        return (
        <form onSubmit={this.handleSubmit}>
            <label>
            Name:
            <input type="text" value={this.state.name} onChange={this.handleChangeName} />
            </label>
            <label>
            Level:
            <input type="text" value={this.state.level} onChange={this.handleChangeLevel} />
            </label>
            <input type="submit" value="Submit" />
        </form>
        );
    }
}

class XPTable extends React.Component {
    constructor(props) {
        super(props);
        let getData = localStorage.getItem('ct_characters');
        if (getData !== '' && getData !== null) {
            this.state = JSON.parse(getData);
        } else {
            this.state = {characters: {}};
        }
        this.loadFile = undefined;
    }

    renderLine(character) {
        return (<tr>
            <td>{this.state.characters[character].name}</td>
            <td>Level {this.state.characters[character].level}</td>
            <td>{this.state.characters[character].marks}</td>
            <td>/</td>
            <td>{xpneeded(this.state.characters[character].level)}</td>
            <td><button onClick={() => this.setState((state) => {
                var newState = {};
                Object.assign(newState, state);
                newState.characters[character] = setMarks(character, state, 1);
                return newState;
            })}>+</button></td>
            <td><button onClick={() => this.setState((state) => {
                var newState = {};
                Object.assign(newState, state);
                newState.characters[character] = setMarks(character, state, -1);
                return newState;
             })}>-</button></td>
            <td><XPChangeForm onSubmit={(amount) => this.setState((state) => {
                var newState = {};
                Object.assign(newState, state);
                newState.characters[character] = setMarks(character, state, amount);
                return newState;
            })}/></td>
            <td><button onClick={() => this.setState((state) => {
                var newState = {};
                Object.assign(newState, state);
                delete newState.characters[character];
                return newState;
            })}>delete</button></td>
        </tr>);
    }

    reload(json) {
        this.setState(json);
    }

    loadGame = event => {
        try {
            if (this.loadFile === undefined) {
                alert("No file selected");
                return;
            }
            console.log(this.loadFile);
            var fileReader = new FileReader();
            fileReader.onloadend = () => {
                this.reload(JSON.parse(fileReader.result));
            };
            fileReader.readAsText(this.loadFile);
        } catch (e) {
            alert("Could not load file: " + e)
        }
    };

    render() {
        return (<div><table>
            {Object.keys(this.state.characters).map((character) => (
                this.renderLine(character)
            ))}
        </table>
        <AddRowForm onSubmit={(newName, newLevel) => this.setState((state) => {
            var newState = {};
            Object.assign(newState, state);
            newState.characters[newName] = {};
            newState.characters[newName].name = newName;
            newState.characters[newName].level = parseInt(newLevel);
            newState.characters[newName].marks = 0;
            return newState;
        })}/>
        <a class="btn btn-primary" href={
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(this.state))
        } download="sessionData.cmtr">save</a>
        <input type="file" accept=".cmtr" onChange={event => {this.loadFile = event.target.files[0]} } />
        <button onClick={this.loadGame}>Load</button>
        <Beforeunload onBeforeunload={() => localStorage.setItem('ct_characters', JSON.stringify(this.state))} />
        </div>);
    }
}

class XPTracker extends React.Component {
    render() {
        return (<div>
            <div>
                <h1>Add marks to characters</h1>
            </div>
            <div>
                <XPTable />
            </div>
        </div>);
    }
}

function xpneeded(level) {
    return Math.floor((level+7)/4)*10;
}

function setMarks(character, state, change) {
    let newMarks = state.characters[character].marks + parseInt(change);
    let level = state.characters[character].level
    if (newMarks < 0) {
        newMarks = 0;
    }
    while (newMarks >= xpneeded(state.characters[character].level)) {
        newMarks -= xpneeded(state.characters[character].level);
        level++;
    }
    return {
        name: state.characters[character].name,
        level: level,
        marks: newMarks,
    }
}

// ========================================

ReactDOM.render(
    <XPTracker />,
    document.getElementById('root')
);